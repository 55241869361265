
	import _prop from 'vue-types';

	export default {
		name: 'form-input-simple',
		inheritAttrs: false,
		props: {
			value: _prop.string,
		},
		emits: ['input', 'change', 'focus', 'blur'],
		data: () => ({}),
		computed: {
			cleanProps() {
				return {
					...this.$attrs,
					hash: undefined,
					validators: false,
					mask: false,
				};
			},
		},
		methods: {},
	};
